
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

import { GoogleAuth, FacebookAuth } from "@xtiannyeto/vue-auth-social";
// import "~@xtiannyeto/vue-auth-social/dist/vue-auth-social.css";

export default defineComponent({
  name: "SignIn",
  components: {
    Field,
    Form,
    ErrorMessage,
    GoogleAuth,
    FacebookAuth,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const redirectURL = ref("");

    const emailVerificationRedirection = ref("");
    emailVerificationRedirection.value = route.query.email_verify as string;

    const emailVerified = ref("");
    emailVerified.value = route.query.email_verified as string;

    const googleClientId = "699744915888-v45ki31o9cm714qlpej0106qt7ekdk6h";
    const facebookAppId = "540291273969666";

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().email().required().label("Username"),
      password: Yup.string().required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.PURGE_AUTH);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "You have successfully logged in!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Go to page after successfully login
            // ApiService.setHeader();
            redirectURL.value = route.query.redirect as string;
            if (redirectURL.value) {
              router.push(redirectURL.value);
            } else {
              router.push({ name: "dashboard" });
            }
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    const onGoogleOrFacebookLogin = (event: any) => {
      let sResponse = JSON.parse(JSON.stringify(event));

      // On Success Login
      if (Object.prototype.hasOwnProperty.call(sResponse, "googleUser") || Object.prototype.hasOwnProperty.call(sResponse, "authResponse")) {
        var payload = {};

        // If Google
        if (Object.prototype.hasOwnProperty.call(sResponse, "googleUser")) {
          var token = event.googleUser.getAuthResponse(true).access_token;
          payload = {
            provider: "google",
            provider_uid: sResponse.id,
            provider_token: token,
          };
        }
        // If Facebook
        if (Object.prototype.hasOwnProperty.call(sResponse, "authResponse")) {
          payload = {
            provider: "facebook",
            provider_uid: sResponse.authResponse.userID,
            provider_token: sResponse.authResponse.accessToken,
          };
        }

        if (payload)
          store
            .dispatch(Actions.SOCIAL_AUTH, payload)
            .then(() => {
              Swal.fire({
                text: "You have successfully logged in!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                redirectURL.value = route.query.redirect as string;
                if (redirectURL.value) {
                  router.push(redirectURL.value);
                } else {
                  router.push({ name: "dashboard" });
                }
              });
            })
            .catch((response) => {
              if (response.code == "ACCOUNT_NOT_EXISTS") {
                router.push({ name: "socialLink" });
              } else {
                const [error] = Object.keys(store.getters.getErrors);
                Swal.fire({
                  text: store.getters.getErrors[error],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
            });
      }
    };

    return {
      googleClientId,
      facebookAppId,
      onGoogleOrFacebookLogin,
      onSubmitLogin,
      login,
      submitButton,
      emailVerificationRedirection,
      emailVerified,
    };
  },
});
